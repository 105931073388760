import { useIntl } from 'react-intl'

export type TByKey = (msg: string, args?: { [key: string]: string }) => string

export const useT = (): TByKey => {
    const intl = useIntl()

    // TODO
    return (key, args) => intl.formatMessage({ id: key })
}
