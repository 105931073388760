import React from 'react'
import Image, { ImageSrc } from 'src/components/Image'
import useBodyScroll from 'src/hooks/useBodyScroll'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { getWindow } from 'src/util/getWindow'
import { useTheme } from '@emotion/react'
import { useT } from 'src/i18n/useT'
import { msg } from 'src/i18n/translation/MsgKeys'
import { Parallax } from 'src/components/Parallax'
import ClutchWidget from 'src/components/ClutchWidget'

const calcTextOpacity = (): number => {
    const bodyScroll = useBodyScroll()
    const { innerHeight: viewHeight } = getWindow({ innerHeight: 1 })
    // Calculated variable which determines number that multiplies height ratio, because of different viewport heights
    const viewHeightRatio = 1.8 / (viewHeight / bodyScroll.documentHeight)
    return 1 - bodyScroll.heightRatio * viewHeightRatio
}

const HeroText = ({ title, subtitle }: { title: string; subtitle: string }) => {
    const textOpacity = calcTextOpacity()
    return (
        <div
            css={(th: Theme) => css`
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 90vw;
                transform: translate(0, 10em);
                @media (max-width: ${th.mediaQuery.xl}) {
                    transform: translate(0, 6em);
                }
                // Remove y offset on smaller screens
                @media (max-width: ${th.mediaQuery.md}) {
                    transform: translate(0, 0%);
                }
                > *:not(:last-child) {
                    margin-bottom: ${th.spacing.md};
                }
                opacity: ${textOpacity};
                color: ${th.colors.white};
                font-size: ${th.fontSize.md};
                font-weight: ${th.fontWeight.thin};
                text-align: center;
            `}
        >
            <h1
                css={(th: Theme) => css`
                    font-size: 4rem;
                    font-weight: ${th.fontWeight.bold};
                    text-transform: uppercase;
                    @media (max-width: ${th.mediaQuery.xl}) {
                        font-size: 3.5rem;
                    }
                    @media (max-width: ${th.mediaQuery.md}) {
                        font-size: 3rem;
                    }
                    @media (max-width: ${th.mediaQuery.sm}) {
                        font-size: 2rem;
                    }
                `}
            >
                {title}
            </h1>
            <span
                css={(th: Theme) => css`
                    font-size: ${th.fontSize.lg};
                `}
            >
                {subtitle}
            </span>
        </div>
    )
}

const heroResolutionHeights = (th: Theme) => css`
    @media (max-width: ${th.mediaQuery.md}) {
        height: 700px;
    }
    @media (max-width: ${th.mediaQuery.sm}) {
        height: 600px;
    }
    @media (max-width: ${th.mediaQuery.xs}) {
        height: 500px;
    }
`

type HeroProps = {
    img: ImageSrc
}

// const Video = () => {
//     return (
//         <video
//             css={css`
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//             `}
//             autoPlay
//             muted
//             id="myVideo"
//             preload="auto"
//             loop={true}
//             // TODO later update
//             poster="https://v3.cdnpk.net/videvo_files/video/free/2022-01/thumbnails/220114_01_Drone_4k_011_small.jpg"
//         >
//             <source
//                 src="https://v3.cdnpk.net/videvo_files/video/free/2022-01/large_watermarked/220114_01_Drone_4k_011_preview.mp4"
//                 type="video/mp4"
//             />
//             {"Your browser doesn't support this video format."}
//         </video>
//     )
// }

const Hero = ({ img }: HeroProps) => {
    const th = useTheme() as Theme
    const t = useT()
    return (
        <div
            css={css`
                position: relative;
                overflow: hidden;
                height: 100vh;
                ${heroResolutionHeights(th)};
                background: ${th.colors.white};
            `}
        >
            <ClutchWidget />
            {/* Has to be low number, because full screen image moving at big rates was lagging */}
            <Parallax y={[-25, 25]}>
                {/* <Video /> */}
                <Image
                    src={img}
                    alt="Garwan"
                    css={css`
                        object-fit: cover;
                        height: 100%;
                        filter: brightness(80%);
                    `}
                    backgroundColor={th.colors.white}
                />
            </Parallax>
            <Parallax
                y={[-15, 15]}
                styleInner={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                // styles outer
                css={css`
                    position: absolute;
                    width: 100%;
                    top: 0;
                    left: 0;
                `}
            >
                <HeroText
                    title={t(msg.homePage.hero.title)}
                    subtitle={t(msg.homePage.hero.subTitle)}
                />
            </Parallax>
        </div>
    )
}

export default Hero
