import { Link } from 'src/components/Link'
import React, { ReactNode } from 'react'
import Container from 'src/components/Container'
import Header from 'src/components/Header'
import Button from 'src/components/ui/kit/Button'
import { t } from 'src/i18n/translation/t'
import { Msgs } from 'src/i18n/translation/types'
import { IconType } from 'src/icons'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import MaxWidthContainer from 'src/components/MaxWidthContainer'

type SectionProps = {
    title: string
    icon: IconType
    children: ReactNode
    msgs: Msgs
    fullWidth: boolean
    linkToMore?: string
    grayHeader?: boolean
}

const Section = ({
    title,
    icon: Icon,
    children,
    msgs,
    fullWidth,
    linkToMore,
    grayHeader
}: SectionProps) => (
    <div>
        <div
            css={(th: Theme) => css`
                background-color: ${grayHeader
                    ? th.colors.lightestGray
                    : 'transparent'};
            `}
        >
            <Container
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                <Header size={2}>{title}</Header>
                <Icon
                    css={(th: Theme) => css`
                        font-size: ${th.fontSize.lg};
                    `}
                />
            </Container>
        </div>
        {fullWidth ? (
            children
        ) : (
            <MaxWidthContainer>{children}</MaxWidthContainer>
        )}

        {linkToMore && (
            <div
                css={(th: Theme) => css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: ${th.spacing.containerDivider} 0;
                `}
            >
                <Link to={linkToMore} aria-label={t(msgs.homePage.more)}>
                    <Button onClick={() => ''}>{t(msgs.homePage.more)}</Button>
                </Link>
            </div>
        )}
    </div>
)

export default Section
