import React, { CSSProperties, ReactElement } from 'react'
import { Parallax as RParallax } from 'react-scroll-parallax'
import { css } from 'src/style/css'

// Not used right now
// export const ParallaxCache = () => {
//     const context = React.useContext(ParallaxContext)
//     if (context) {
//         const { parallaxController } = useController()
//
//         useLayoutEffect(() => {
//             const handler = () => parallaxController.update()
//             window.addEventListener('load', handler)
//             return () => window.removeEventListener('load', handler)
//         }, [parallaxController])
//     }
//     return null
// }

type ParallaxProps = {
    y: number[]
    styleInner?: CSSProperties
    children: ReactElement
    className?: string
}

export const Parallax = ({
    y,
    styleInner,
    children,
    className
}: ParallaxProps) => {
    return (
        <RParallax
            y={y}
            styleInner={{
                height: '100%',
                ...styleInner
            }}
            css={css`
                height: 100%;
            `}
            className={className}
        >
            {children}
        </RParallax>
    )
}
