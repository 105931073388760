import { graphql } from 'gatsby'
import { take } from 'ramda'
import React from 'react'
import AboutUs from 'src/components/home/AboutUs'
import Hero from 'src/components/home/Hero'
import ProjectCycle from 'src/components/home/ProjectCycle'
import Layout from 'src/components/Layout'
import {
    unwrapReferenceFromQueryAndSetLangPath,
    WrappedReferenceQueryResult
} from 'src/model/referenceQuery'
import {
    unwrapTeamMemberFromQuery,
    WrappedTeamMemberQueryResult
} from 'src/model/teamMemberQuery'
import { SrcImagesFragment } from 'src/page/fragments'
import { PageContext } from 'src/page/types'
import { t } from 'src/i18n/translation/t'
import Icon from 'src/icons'
import Section from 'src/components/home/Section'
import ReferencesGrid from 'src/components/ReferencesGrid'
import TeamGrid from 'src/components/TeamGrid'
import Companies from 'src/components/Companies'
import { pageNames } from 'src/pageNames'
import {
    unwrapServiceFromQueryAndSetLangPath,
    WrappedServiceQueryResult
} from 'src/model/serviceQuery'
import {
    unwrapCompanyFromQuery,
    WrappedCompanyQueryResult
} from 'src/model/companyQuery'
import CallToAction from 'src/components/CallToAction'
import { isTabletScreen } from 'src/style/ScreenSize'

type IndexPageProps = {
    data: {
        referencesPage: {
            path: string
        }
        heroImg: SrcImagesFragment
        aboutUsImgFirst: SrcImagesFragment
        aboutUsImgSecond: SrcImagesFragment
        teamMembers: WrappedTeamMemberQueryResult
        references: WrappedReferenceQueryResult
        teamPage: {
            path: string
        }
        companies: WrappedCompanyQueryResult
        services: WrappedServiceQueryResult
    }
    pageContext: PageContext
}
const IndexPage = ({ data, pageContext }: IndexPageProps) => {
    const isTablet = isTabletScreen()
    const { lang, msgs } = pageContext
    const { heroImg, aboutUsImgFirst, aboutUsImgSecond } = data

    const aboutUsImgs = [aboutUsImgFirst, aboutUsImgSecond]

    const allTeamMembers = unwrapTeamMemberFromQuery(data.teamMembers)
    const allReferences = unwrapReferenceFromQueryAndSetLangPath(
        data.references,
        lang
    )
    const allServices = unwrapServiceFromQueryAndSetLangPath(
        data.services,
        lang
    )
    const allCompanies = unwrapCompanyFromQuery(data.companies)
    const teamMembers = take(12, allTeamMembers)
    const references = take(isTablet ? 8 : 9, allReferences)
    const homePageMsgs = msgs.homePage
    return (
        <Layout pageCtx={pageContext} overlayNav={true}>
            {({ ctx: { nav } }) => (
                <>
                    <Hero img={heroImg} />
                    <AboutUs
                        img={aboutUsImgs}
                        msgs={msgs}
                        services={allServices}
                    />
                    <ProjectCycle msgs={msgs} />
                    <Section
                        title={t(homePageMsgs.references.title)}
                        icon={Icon.Check}
                        linkToMore={nav.linkTo(pageNames.references)}
                        msgs={msgs}
                        fullWidth
                        grayHeader
                    >
                        <ReferencesGrid
                            items={references}
                            moreInfo={t(msgs.references.moreInfo)}
                        />
                    </Section>
                    <Section
                        title={t(msgs.team.title)}
                        icon={Icon.Users}
                        linkToMore={nav.linkTo(pageNames.team)}
                        msgs={msgs}
                        fullWidth={false}
                        grayHeader
                    >
                        <TeamGrid items={teamMembers} />
                    </Section>
                    <Companies items={allCompanies} />
                    <CallToAction
                        linkText={t(homePageMsgs.callToAction.linkText)}
                    />
                </>
            )}
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query HomePageData {
        heroImg: file(
            sourceInstanceName: { eq: "src-images" }
            name: { eq: "hero-new" }
        ) {
            ...SrcImages_FullWidth
        }
        aboutUsImgFirst: file(
            sourceInstanceName: { eq: "src-images" }
            name: { eq: "about-us-1" }
        ) {
            ...SrcImages_FullWidth
        }
        aboutUsImgSecond: file(
            sourceInstanceName: { eq: "src-images" }
            name: { eq: "about-us-2" }
        ) {
            ...SrcImages_FullWidth
        }
        references: referenceOrderJson {
            ...References
        }
        teamMembers: teamOrderJson {
            ...TeamMembers
        }
        companies: companyOrderJson {
            ...Companies
        }
        services: serviceOrderJson {
            ...Services
        }
    }
`
