export const msg = {
    contactPage: {
        title: 'contactPage.title',
        subtitle: 'contactPage.subtitle',
        name: 'contactPage.name',
        company: 'contactPage.company',
        services: 'contactPage.services',
        servicesPlaceholder: 'contactPage.servicesPlaceholder',
        servicesOptions: {
            software: 'contactPage.servicesOptions.software',
            mobile: 'contactPage.servicesOptions.mobile',
            web: 'contactPage.servicesOptions.web',
            nearshoring: 'contactPage.servicesOptions.nearshoring',
            other: 'contactPage.servicesOptions.other'
        },
        message: 'contactPage.message',
        gdprConsent: 'contactPage.gdprConsent',
        gdprConsentLink: 'contactPage.gdprConsentLink',
        submit: 'contactPage.submit'
    },
    careerPage: {
        title: 'careerPage.title',
        subtitle: 'careerPage.subtitle',
        formTitle: 'careerPage.formTitle',
        formSubtitle: 'careerPage.formSubtitle',
        position: 'careerPage.position',
        positionPlaceholder: 'careerPage.positionPlaceholder',
        cv: 'careerPage.cv',
        jobTypePlaceholder: 'careerPage.jobTypePlaceholder',
        jobTypeOptions: {
            fullTime: 'careerPage.jobTypeOptions.fullTime',
            contract: 'careerPage.jobTypeOptions.contract',
            company: 'careerPage.jobTypeOptions.company',
            agreement: 'careerPage.jobTypeOptions.agreement'
        },
        error: 'careerPage.error',
        anyPositionTitle: 'careerPage.anyPositionTitle',
        anyPositionDescription: 'careerPage.anyPositionDescription',
        otherPosition: 'careerPage.otherPosition',
        shareLink: 'careerPage.shareLink',
        ourPlace: 'careerPage.ourPlace'
    },
    homePage: {
        hero: {
            aboveTitle: 'homePage.hero.aboveTitle',
            title: 'homePage.hero.title',
            subTitle: 'homePage.hero.subTitle',
            description: 'homePage.hero.description'
        },
        aboutUs: {
            title: 'homePage.aboutUs.title',
            description: 'homePage.aboutUs.description'
        },
        servicesTitle: 'homePage.servicesTitle',
        moreInfo: 'homePage.moreInfo',
        projectCycle: {
            title: 'homePage.projectCycle.title',
            description: 'homePage.projectCycle.description',
            steps: [Object]
        },
        references: { title: 'homePage.references.title' },
        instagram: { title: 'homePage.instagram.title' },
        more: 'homePage.more'
    },
    career: {
        interested: 'career.interested',
        basicInfo: 'career.basicInfo',
        location: 'career.location',
        jobStart: 'career.jobStart',
        jobType: 'career.jobType',
        salary: 'career.salary',
        requirements: 'career.requirements',
        offerings: 'career.offerings',
        tasks: 'career.tasks'
    },
    team: {
        title: 'team.title',
        thisIsUs: 'team.thisIsUs',
        moreInfo: 'team.moreInfo'
    },
    references: {
        title: 'references.title',
        about: 'references.about',
        moreInfo: 'references.moreInfo'
    },
    services: {
        title: 'services.title',
        software: {
            title: 'services.software.title'
        },
        mobile: {
            title: 'services.mobile.title'
        },
        web: {
            title: 'services.web.title'
        },
        nearshoring: {
            title: 'services.nearshoring.title'
        }
    },
    servicesPage: {
        introText: 'servicesPage.introText',
        countriesList: 'servicesPage.countriesList',
        areasOfProjectsText: 'servicesPage.areasOfProjectsText',
        areasOfProjectsList: 'servicesPage.areasOfProjectsList'
    },
    privacyPolicy: {
        title: 'privacyPolicy.title',
        section1: {
            heading: 'privacyPolicy.section1.heading',
            subheading: 'privacyPolicy.section1.subheading',
            p1: 'privacyPolicy.section1.p1',
            ul1: 'privacyPolicy.section1.ul1',
            p2: 'privacyPolicy.section1.p2',
            p3: 'privacyPolicy.section1.p3',
            ul2: 'privacyPolicy.section1.ul2',
            p4: 'privacyPolicy.section1.p4'
        },
        section2: {
            heading: 'privacyPolicy.section2.heading',
            subheading: 'privacyPolicy.section2.subheading',
            tableSections: 'privacyPolicy.section2.tableSections'
        },
        section3: {
            heading: 'privacyPolicy.section3.heading',
            subheading: 'privacyPolicy.section3.subheading',
            tableSections: 'privacyPolicy.section3.tableSections'
        },
        section4: {
            heading: 'privacyPolicy.section4.heading',
            subheading: 'privacyPolicy.section4.subheading',
            tableSections: 'privacyPolicy.section4.tableSections'
        },
        sup: 'privacyPolicy.sup'
    },
    cookies: { title: 'cookies.title', sections: 'cookies.sections' },
    notFound: { title: 'notFound.title' },
    contact: {
        title: 'contact.title',
        phone: 'contact.phone',
        email: 'contact.email'
    },
    billing: {
        title: 'billing.title',
        registered: 'billing.registered',
        companyId: 'billing.companyId',
        vatId: 'billing.vatId',
        taxId: 'billing.taxId',
        bank: {
            title: 'billing.bank.title',
            accountId: 'billing.bank.accountId',
            swift: 'billing.bank.swift',
            iban: 'billing.bank.iban'
        }
    },
    copyrights: 'copyrights',
    pages: {
        home: 'pages.home',
        services: 'pages.services',
        team: 'pages.team',
        references: 'pages.references',
        career: 'pages.career',
        contact: 'pages.contact',
        privacyPolicy: 'pages.privacyPolicy'
    },
    generalForm: {
        name: 'generalForm.name',
        firstName: 'generalForm.firstName',
        lastName: 'generalForm.lastName',
        message: 'generalForm.message',
        gdprConsent: 'generalForm.gdprConsent',
        gdprConsentLink: 'generalForm.gdprConsentLink',
        submit: 'generalForm.submit',
        submitSucceeded: 'generalForm.submitSucceeded',
        submitError: 'generalForm.submitError'
    },
    meta: {
        title: 'meta.title',
        desc: 'meta.desc'
    },
    cookieBar: {
        accept: 'cookieBar.accept',
        decline: 'cookieBar.decline',
        title: 'cookieBar.title',
        description: 'cookieBar.description',
        essentials: 'cookieBar.essentials',
        analytics: 'cookieBar.analytics',
        settings: 'cookieBar.settings',
        moreInformation: 'cookieBar.moreInformation',
        save: 'cookieBar.save'
    }
}
