import React from 'react'
import Container from 'src/components/Container'
import { t } from 'src/i18n/translation/t'
import { Msgs } from 'src/i18n/translation/types'
import Icon, { IconType } from 'src/icons'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { grid } from 'src/style/mixins'
import Header from 'src/components/Header'

const Step = ({
    icon: Icon,
    title,
    description
}: {
    icon: IconType
    title: string
    description: string
}) => (
    <div>
        <div
            css={(th: Theme) => css`
                display: flex;
                align-items: center;
                margin-bottom: ${th.spacing.md};
            `}
        >
            <Icon
                css={(th: Theme) => css`
                    color: ${th.colors.primary};
                    font-size: 20px;
                `}
            />
            <span
                css={(th: Theme) => css`
                    font-weight: ${th.fontWeight.semiBold};
                    padding-left: ${th.spacing.md};
                `}
            >
                {title}
            </span>
        </div>
        <p
            css={css`
                text-align: justify;
            `}
        >
            {description}
        </p>
    </div>
)

type ProjectCycle = {
    msgs: Msgs
}

type StepDef = {
    icon: IconType
    name:
        | 'idea'
        | 'priceOffer'
        | 'projectSpecification'
        | 'design'
        | 'coding'
        | 'testing'
        | 'deploy'
        | 'monitoring'
        | 'extend'
}

const steps: StepDef[] = [
    {
        icon: Icon.Bulb,
        name: 'idea'
    },
    {
        icon: Icon.Eye,
        name: 'priceOffer'
    },
    {
        icon: Icon.File,
        name: 'projectSpecification'
    },
    {
        icon: Icon.Design,
        name: 'design'
    },
    {
        icon: Icon.Code,
        name: 'coding'
    },
    {
        icon: Icon.Check,
        name: 'testing'
    },
    {
        icon: Icon.Upload,
        name: 'deploy'
    },
    {
        icon: Icon.FindReplace,
        name: 'monitoring'
    },
    {
        icon: Icon.Add,
        name: 'extend'
    }
]

const ProjectCycle = ({ msgs }: ProjectCycle) => {
    const projectCycleMsgs = msgs.homePage.projectCycle
    const projectCycleStepsMsgs = msgs.homePage.projectCycle.steps
    const IconComp = Icon.Refresh
    return (
        <div>
            <div
                css={(th: Theme) => css`
                    background: ${th.colors.primary};
                    width: 100%;
                `}
            >
                <Container
                    css={(th: Theme) => css`
                        color: ${th.colors.white};
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        text-align: center;
                    `}
                >
                    <Header size={2}>{t(projectCycleMsgs.title)}</Header>
                    <IconComp
                        css={(th: Theme) => css`
                            font-size: ${th.fontSize.lg};
                        `}
                    />
                    <span>{t(projectCycleMsgs.description)}</span>
                </Container>
            </div>
            <Container
                css={(th: Theme) => css`
                    color: ${th.colors.darkGray};
                `}
            >
                <div
                    css={(th: Theme) => css`
                        ${grid({ minWidth: '18em', gap: th.spacing.xl })}
                    `}
                >
                    {steps.map((s, index) => (
                        <Step
                            key={s.name}
                            icon={s.icon}
                            title={`${index + 1}. ${t(
                                projectCycleStepsMsgs[s.name].title
                            )}`}
                            description={t(
                                projectCycleStepsMsgs[s.name].description
                            )}
                        />
                    ))}
                </div>
            </Container>
        </div>
    )
}

export default ProjectCycle
