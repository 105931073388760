import React from 'react'
import Container from 'src/components/Container'
import Header from 'src/components/Header'
import { gatsbyImgDataToHTMLImage, ImageSrc } from 'src/components/Image'
import { t } from 'src/i18n/translation/t'
import { Msgs } from 'src/i18n/translation/types'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { ServiceInfo } from 'src/model/serviceType'
import { ServicesGrid } from 'src/components/ServicesGrid'
import { Slider } from 'src/components/Slider'

type AboutUs = {
    img: ImageSrc[]
    msgs: Msgs
    services: ServiceInfo[]
}

const Item = ({ msgs, img }: { msgs: any; img: ImageSrc }) => {
    const imageData = gatsbyImgDataToHTMLImage(img)
    return (
        <div
            css={(th: Theme) => css`
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                @media (max-width: ${th.mediaQuery.md}) {
                    flex-direction: column;
                }
            `}
        >
            <div
                css={(th: Theme) => css`
                    width: 40%;
                    color: ${th.colors.black};
                    @media (max-width: ${th.mediaQuery.md}) {
                        width: 100%;
                    }
                    margin-right: 1rem;
                `}
            >
                <Header size={2} uppercase>
                    {t(msgs.title)}
                </Header>
                <hr
                    css={(th: Theme) => css`
                        width: 40px;
                        height: 4px;
                        background: ${th.colors.primary};
                        margin: ${th.spacing.md} 0;
                    `}
                />
                <p
                    css={(th: Theme) => css`
                        font-weight: ${th.fontWeight.thin};
                        font-size: ${th.fontSize.md};
                        line-height: 1.8;
                    `}
                >
                    {t(msgs.description)}
                </p>
            </div>
            <img
                src={imageData.src}
                sizes={imageData.sizes}
                srcSet={imageData.srcSet}
                width="60%"
                height="100%"
                alt="Garwan"
                css={(th: Theme) => css`
                    width: 60%;
                    @media (max-width: ${th.mediaQuery.md}) {
                        width: 100%;
                        margin: 0;
                    }
                    margin: 1px;
                `}
            />
        </div>
    )
}

const AboutUs = ({ img, msgs, services }: AboutUs) => {
    const homePage = msgs.homePage
    const aboutMsgs = homePage.aboutUs

    return (
        <div>
            <Container>
                <Slider>
                    {aboutMsgs.map((message, i) => (
                        <div key={i}>
                            <Item msgs={message} img={img[i]} />
                        </div>
                    ))}
                </Slider>
                <div
                    css={css`
                        text-align: center;
                    `}
                >
                    <Header size={2}>{t(homePage.servicesTitle)}</Header>
                </div>
                <ServicesGrid items={services} msgs={msgs} />
            </Container>
        </div>
    )
}

export default AboutUs
